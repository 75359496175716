<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="suchen"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="experts"
      :search="search"
      :loading="experts.length === 0"
    >
      <template v-slot:item.s_Language="{ item }">
        {{ item.s_Language }}
      </template>
      <template v-slot:item.a_Record="{ item }">
        <v-chip
          :color="getColor(item.i_LastYear)"
          dark
          @click="selectExpert(item.i_AddressRoleID)"
        >
          <v-icon
            color="white"
            left
          >
            mdi-history
          </v-icon>
          {{ item.i_LastYear }}
        </v-chip>
      </template>
      <template v-slot:item.s_Availability="{ item }">
        <v-icon
          color="primary"
          @click="getAvalability(item.i_AddressRoleID)"
        >
          mdi-calendar-check
        </v-icon>
      </template>
      <template v-slot:item.s_Activity="{ item }">
        <v-icon
          color="primary"
          @click="getActivity(item.i_AddressRoleID)"
        >
          mdi-format-list-checkbox
        </v-icon>
      </template>
      <template v-slot:item.a_Skills="{ item }">
        <v-select
          v-model="item.a_Skills"
          :items="a_Skills"
          class="mt-4"
          item-text="s_Name"
          item-value="i_SkillID"
          @change="setTeacherSkills(item)"
          chips
          multiple
          dense
        />
      </template>
      <template v-slot:item.b_Add="{ item }">
        <v-icon
          class="mr-2"
          :color="getPlanningIcon(item.i_AddressRoleID).s_Color"
          large
          @click="setPlanningStatus(item.i_AddressRoleID)"
        >
          {{ getPlanningIcon(item.i_AddressRoleID).s_Symbol }}
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Historie</span>
        </v-card-title>
        <v-card-text>
          <div
            v-for="(o_Record, index) in selectedExpert.a_Record"
            :key="index"
          >
            <v-chip
              class="pr-10"
              color="gray"
            >
              <v-icon
                left
              >
                mdi-history
              </v-icon>
              {{ o_Record.i_Year }}
            </v-chip><br />
            <div class="ml-3 mb-2">
              {{ o_Record.s_ExamStaff }} <br />
              {{ o_Record.s_ExamType }} <br />
              Tage: {{ o_Record.i_Days }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAvalability"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Verfügbarkeit</span>
        </v-card-title>
        <v-card-text>
          <Availability
            :teacherID="teacherID"
            :key="teacherID"
            :periodID="periodID"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="dialogAvalability=false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogActivity"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-toolbar
          flat
          dark
          color="primary"
        >
          <v-toolbar-title>Einsatz</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="dialogActivity = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <Activity
            :teacherID="teacherID"
            :key="teacherID"
            :periodID="periodID"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="dialogActivity=false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import { get, post } from '../../../worker/worker-api'
  import { mapGetters, mapActions } from 'vuex'
  import { config } from '../../../data/config'
  import Availability from '../../../components/SMT/Availability'
  import Activity from '../../../components/SMT/Activity'

  export default {
    name: 'ExpertsRecord',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Experten-Historie',
    },
    components: {
      Availability,
      Activity,
    },
    data () {
      return {
        teacherID: 0,
        periodID: this.getPlanningPeriodID,
        dialog: false,
        dialogAvalability: false,
        dialogActivity: false,
        selectedExpert: {
          i_AddressRoleID: null,
          s_Lastname: null,
          s_Firstname: null,
          s_Language: null,
          i_LastYear: null,
          a_Record: [],
          a_Skills: [],
        },
        a_SelectedSkills: [],
        search: '',
        snack: false,
        reasonDialog: false,
        snackColor: '',
        snackText: '',
        pagination: {},
        headers: [
          {
            text: 'Nachname',
            align: 'start',
            value: 's_Lastname',
          },
          { text: 'Vorname', value: 's_Firstname' },
          { text: 'Sprachen', value: 's_Language' },
          { text: 'Historie', value: 'a_Record' },
          {
            text: 'Verfügbarkeit',
            value: 's_Availability',
            sortable: false,
          },
          {
            text: 'Einsatz',
            value: 's_Activity',
            sortable: false,
          },
          {
            text: 'Qualifikationen',
            value: 'a_Skills',
            sortable: false,
          },
          {
            text: 'planen',
            value: 'b_Add',
            sortable: false,
          },
        ],
        experts: [],
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID', 'getCurrentExamEvent', 'getInitialConfig']),
      a_Skills () {
        return this.getInitialConfig.a_ExpertSkills
      }
    },
    watch: {
    },
    beforeMount () {
      this.getExperts()
    },
    methods: {
      ...mapActions(['signOut']),
      getExperts () {
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher`, {
        })
          .then(response => {
            if (response.status === 401) {
              // this.signOut()
              // this.$smt.logout()
              return
            }
            this.experts = response.sort((a, b) => a.s_Lastname.localeCompare(b.s_Lastname))
            this.setLastPlanningyear()
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setLastPlanningyear () {
        this.experts.forEach(expert => {
          expert.i_LastYear = expert.a_Record.length > 0
            ? Math.max(...expert.a_Record.map(record => record.i_Year))
            : 'keine'
        })
      },
      getColor (year) {
        if (year === new Date().getFullYear() - 1) return 'success'
        if (year === new Date().getFullYear() - 2) return 'orange'
        else return 'danger'
      },
      getPlanningIcon (Ai_AddressRoleID) {
        const La_Expert = this.experts.filter(expert => expert.i_AddressRoleID === Ai_AddressRoleID)
        if (La_Expert[0].a_PlanningStatus.findIndex(
          attribute => attribute.i_AttributeID === config.attribute.i_Experte2022) > -1
        ) {
          return { s_Symbol: 'mdi-account-check', s_Color: 'success' }
        }
        if (La_Expert[0].a_PlanningStatus.findIndex(
          attribute => attribute.i_AttributeID === config.attribute.i_NichtExperte2022) > -1
        ) {
          return { s_Symbol: 'mdi-account-remove', s_Color: 'danger' }
        }
        return { s_Symbol: 'mdi-account', s_Color: 'gray' }
      },
      setPlanningIcon (Ai_AddressRoleID, Ao_PlanningStatus) {
        const Li_Index = this.experts.findIndex(expert => expert.i_AddressRoleID === Ai_AddressRoleID)
        if (Li_Index === -1) return
        if (Ao_PlanningStatus !== null) {
          this.experts[Li_Index].a_PlanningStatus = [Ao_PlanningStatus]
        } else {
          this.experts[Li_Index].a_PlanningStatus = []
        }
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.selectedExpert = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      getAvalability (Ai_AddressRoleID) {
        this.teacherID = Ai_AddressRoleID
        this.dialogAvalability = true
      },
      getActivity (Ai_AddressRoleID) {
        this.teacherID = Ai_AddressRoleID
        this.dialogActivity = true
      },
      selectExpert (Ai_AddressRoleID) {
        const Li_Index = this.experts.findIndex(expert => expert.i_AddressRoleID === Ai_AddressRoleID)
        this.selectedExpert = this.experts[Li_Index]
        this.dialog = true
      },
      setRecusal (As_Action) {
        const teacherID = this.$smt.getAddressRoleID(this.$route.meta.role)
        const Li_Index = this.experts.findIndex(candidate => candidate.i_AddressRoleID === this.selectedCandidate.i_AddressRoleID)
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/recusal`, {
          i_CandidateAddressRoleID: this.selectedCandidate.i_AddressRoleID,
          s_Reason: this.selectedCandidate.s_Reason,
          s_Action: As_Action,
        })
          .then(response => {
            this.editMode = false
            if (response.status === 401) {
              // this.signOut()
              // this.$smt.logout()
              return
            }
            this.close()
            this.experts[Li_Index].b_Recusal = !this.experts[Li_Index].b_Recusal
            this.requestSent = true
            this.responseMessage = 'Ausstandsgebehren gesendet'
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setTeacherSkills (Ao_Expert) {
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${Ao_Expert.i_AddressRoleID}/skills`, {
          a_Skills: Ao_Expert.a_Skills,
          i_PlanningPeriodID: this.getCurrentExamEvent.i_PlanningPeriodID,
        })
          .then(response => {
            if (response.status === 401) {
              // this.signOut()
              // this.$smt.logout()
              return
            }
            this.requestSent = true
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setPlanningStatus (Ai_AddressRoleID) {
        let Li_AttributeID = 0
        if (this.getPlanningIcon(Ai_AddressRoleID).s_Symbol === 'mdi-account') {
          Li_AttributeID = config.attribute.i_Experte2022
        }
        if (this.getPlanningIcon(Ai_AddressRoleID).s_Symbol === 'mdi-account-check') {
          Li_AttributeID = config.attribute.i_NichtExperte2022
        }
        if (this.getPlanningIcon(Ai_AddressRoleID).s_Symbol === 'mdi-account-remove') {
          Li_AttributeID = 0
        }
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${Ai_AddressRoleID}/planning-status`, {
          i_AttributeID: Li_AttributeID,
        })
          .then(response => {
            if (response.status === 401) {
              // this.signOut()
              // this.$smt.logout()
              return
            }
            this.requestSent = true
            this.setPlanningIcon(Ai_AddressRoleID, response.o_PlanningStatus)
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
