<template>
  <div>
    <v-row>
      <v-progress-linear
        v-if="!b_IsLoaded"
        indeterminate
        color="primary"
      />
    </v-row>
    <v-row>
      <v-col>
        <v-chip
          v-for="(examDate, index) in a_ExamDate"
          :key="index"
          class="ma-2"
          :color="getColorChip(examDate.type)"
          text-color="white"
          @click="setAvailability(examDate.id)"
        >
          <v-icon
            color="white"
            left
          >
            mdi-calendar
          </v-icon>
          {{ getDateFormat(examDate.examDate) }}
          <v-chip
            class="ml-2"
            x-small
            :text-color="getColorChip(examDate.type)"
            color="white"
          >
            {{ examDate.name }}
          </v-chip>
          <v-icon
            color="white"
            right
          >
            {{ getIconChip(examDate.type) }}
          </v-icon>
        </v-chip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { get, post } from '../../worker/worker-api'
  import { mapGetters } from 'vuex'

  export default {
    metaInfo: {
      title: 'Verfügbarkeit',
    },
    props: {
      teacherID: {
        type: Number,
        default: 0,
      },
      periodID: {
        type: Number,
        default: 0,
      },
    },
    data () {
      return {
        a_ExamDate: [],
        b_IsLoaded: true,
      }
    },
    computed: {
      ...mapGetters(['getCurrentExamEvent']),
      allowedDates () {
        return this.a_AllowedDates
      },
    },
    methods: {
      getExamDates () {
        get(`${process.env.VUE_APP_SMT_API_URL}/data/exam/date`, {
          i_ExamEventID: this.getExamEventID,
        })
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.slots = response
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getAvailability () {
        const events = []
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.teacherID}/period/${this.getCurrentExamEvent.i_PlanningPeriodID}/availability`, {
        })
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            let Ls_Color = this.colorEvent
            const La_Availability = response.Availability
            for (let i = 0; i < La_Availability.length; i++) {
              if (La_Availability[i].i_Type === 1) Ls_Color = 'light-green'
              if (La_Availability[i].i_Type === 2) Ls_Color = 'orange lighten-3'
              if (La_Availability[i].i_Type === 3) Ls_Color = 'orange'
              events.push({
                id: La_Availability[i].i_Id,
                name: La_Availability[i].s_Title,
                examDate: La_Availability[i].t_ExamDate,
                weekDate: La_Availability[i].t_WeekDate,
                timed: 0,
                color: Ls_Color,
                type: La_Availability[i].i_Type,
                available: La_Availability[i].b_Available,
                dayID: La_Availability[i].i_DayID,
                availabilityID: La_Availability[i].i_AvailabilityID,
              })
            }
            this.a_ExamDate = events.sort((a, b) => a.examDate > b.examDate ? 1 : -1)
            this.b_IsLoaded = true
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setAvailability (Ai_Id) {
        const La_SelectedExamDate = this.a_ExamDate.filter(examDate => examDate.id === Ai_Id)
        let Lo_SelectedExamDate
        (La_SelectedExamDate.length > 0)
          ? Lo_SelectedExamDate = La_SelectedExamDate[0]
          : Lo_SelectedExamDate = null
        if (Lo_SelectedExamDate === null) return
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.teacherID}/period/${this.getCurrentExamEvent.i_PlanningPeriodID}/availability`, {
          i_AvailabilityTypeID: this.getNextState(Lo_SelectedExamDate.type),
          i_DayID: Lo_SelectedExamDate.dayID,
          i_AvailabilityID: Lo_SelectedExamDate.availabilityID,
          t_WeekDate: Lo_SelectedExamDate.weekDate.substr(0, 10),
        })
          .then(response => {
            this.editMode = false
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.setNextState(Lo_SelectedExamDate)
            this.requestSent = true
            this.responseMessage = 'Verfügbarkeit gesendet'
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getNextState (Ai_Type) {
        if(Ai_Type === 1) return 2
        if(Ai_Type === 2) return 3
        if(Ai_Type === 3) return 1
        return 3
      },
      setNextState (Ao_SelectedExamDate) {
        const Li_IndexExamDate = this.a_ExamDate.findIndex(examDate => examDate.id === Ao_SelectedExamDate.id)
        const Li_NextState = this.getNextState(Ao_SelectedExamDate.type)
        if (Li_IndexExamDate === -1) this.a_ExamDate[Li_IndexExamDate].type = 3
        else this.a_ExamDate[Li_IndexExamDate].type = Li_NextState
      },
      getColorChip (Ai_Type) {
        switch (Ai_Type) {
          case 1:
            return 'success'
          case 2:
            return 'orange'
          case 3:
            return 'danger'
          default:
            return 'danger'
        }
      },
      getIconChip (Ai_Type) {
        switch (Ai_Type) {
          case 1:
            return 'mdi-account-check'

          case 2:
            return 'mdi-account-alert'

          case 3:
            return 'mdi-account-cancel'

          default:
            return 'mdi-account-cancel'
        }
      },
      getLabelChip(Ai_State) {
        switch (Ai_State) {
          case 1:
            return 'Verfügbar';

          case 2:
            return 'Wahrscheinlich';

          case 3:
            return 'nicht Verfügbar';

          default:
            return 'nicht Verfügbar';
        }
      },
      getEventColor (event) {
          return event.color
      },
      showIntervalLabel (interval) {
          return interval.minute === 0
      },
      getDateFormat (As_Date) {
        let Lo_Date = new Date(As_Date);
        let Ls_DateCHFormat = Lo_Date.getDate() + '.' + (parseInt(Lo_Date.getMonth()) + 1) + '.' + Lo_Date.getFullYear();
        return Ls_DateCHFormat
      },
    },
    created () {
      this.getAvailability()
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>