<template>
  <v-container class="grey lighten-5">
    <v-row no-gutters>
      <v-col
        v-for="(activity, index) in a_Activity"
        :key="index"
        cols="12"
        sm="4"
      >
        <v-card
          class="ma-2"
          outlined
          max-width="300"
        >
          <v-card-title>
            {{ activity.s_Name }}
          </v-card-title>
          <v-card-subtitle>
            {{ getDateFormat(activity.t_Start) }}
          </v-card-subtitle>
          <v-card-actions>
            <v-btn
              text
              color="danger"
              @click="deleteActivity(activity.i_ActivityID)"
            >
              Löschen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-card
          class="ma-2"
          outlined
          max-width="300"
        >
          <v-card-title>
            neuer Einsatz
          </v-card-title>
          <v-card-subtitle>
            hinzufügen
          </v-card-subtitle>
          <v-row
            justify="center"
          >
            <v-card-actions>
              <v-btn
                text
                color="success"
                @click="b_AddActivity = true"
              >
                <v-icon color="success">mdi-view-grid-plus</v-icon>
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="b_AddActivity">
      <v-card
        class="ma-2 pa-2"
        outlined
      >
        <v-toolbar
          flat
          color="gray"
        >
          <v-toolbar-title>Einsatz hinzufügen</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items class="mt-8">
            <v-chip
              text-color="white"
              :color="disableSaveAllActivities ? '' : 'success'"
              dark
              pill
              :disabled="disableSaveAllActivities"
              @click="setSelectedActivities"
            >
              ausgewälte speichern
            </v-chip>
          </v-toolbar-items>
        </v-toolbar>
        <v-col
          v-for="(examDate, index) in a_AvailableExamDate"
          :key="index"
        >
          <v-chip
            color="gray"
            text-color="gray"
            outlined
          >
            <v-avatar left>
              <v-icon>mdi-calendar</v-icon>
            </v-avatar>
            {{ getDateFormat(examDate.t_ExamDate) }}
            <v-divider class="mx-2" vertical />
            {{ examDate.s_Name }}
            <v-menu
              left
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-2"
                  color="success"
                  label
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  {{ examDate.i_ActivityTypeID ? getActivityName(examDate.i_ActivityTypeID) : 'Einsatz' }}
                  <v-avatar right>
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-avatar>
                </v-chip>
              </template>
              <v-list>
                <v-list-item
                  v-for="(activityType) in a_ActivityType"
                  :key="activityType.i_ActivityTypeID"
                  @click="setActivityType(examDate.i_EventID, activityType.i_ActivityTypeID)"
                >
                  <v-list-item-title>{{ activityType.s_Name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-chip
              class="mr-n2"
              small
              text-color="white"
              :color="examDate.i_ActivityTypeID === null ? '' : 'success'"
              dark
              :disabled="examDate.i_ActivityTypeID === null ? true : false"
              @click="setActivity(examDate)"
            >
              speichern
            </v-chip>
          </v-chip>
        </v-col>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import { get, post, del } from '../../worker/worker-api'
  import { mapGetters } from 'vuex'

  export default {
    metaInfo: {
      title: 'Einsatz',
    },
    props: {
      teacherID: {
        type: Number,
        default: 0,
      },
      periodID: {
        type: Number,
        default: 0,
      },
    },
    data () {
      return {
        a_Activity: [],
        a_ExamDate: [],
        b_IsLoaded: true,
        b_AddActivity: false,
        a_ActivityType: [
          {
            s_Name: 'Schriftl. Korrektur',
            i_ActivityTypeID: 1,
          },
          {
            s_Name: 'Mündl. Prüfung 1',
            i_ActivityTypeID: 2,
          },
          {
            s_Name: 'Mündl. Prüfung 2',
            i_ActivityTypeID: 3,
          },
        ],
      }
    },
    computed: {
      ...mapGetters(['getCurrentExamEvent']),
      allowedDates () {
        return this.a_AllowedDates
      },
      a_AvailableExamDate () {
        return this.a_ExamDate.filter(examDate => examDate.b_Available === true)
      },
      disableSaveAllActivities () {
        const La_ActivityWithoutID = this.a_AvailableExamDate.filter(examDate => examDate.i_ActivityTypeID === null)
        if (La_ActivityWithoutID.length === this.a_AvailableExamDate.length) return true
        else return false
      },
    },
    methods: {
      getActivity () {
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.teacherID}/period/${this.getCurrentExamEvent.i_PlanningPeriodID}/activity`, {
        })
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.a_Activity = response.Activity
            this.a_ExamDate = response.ExamDate
            this.b_IsLoaded = true
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getActivityName (Ai_ActivityTypeID) {
        return this.a_ActivityType.filter(
          activityType => activityType.i_ActivityTypeID === Ai_ActivityTypeID
        )[0].s_Name
      },
      setActivityType (Ai_EventID, Ai_ActivityTypeID) {
        const Li_Index = this.a_ExamDate.findIndex(examDate => examDate.i_EventID === Ai_EventID)
        this.a_ExamDate[Li_Index].i_ActivityTypeID = Ai_ActivityTypeID
      },
      setSelectedActivities () {
        const La_SelectedExamDate = this.a_AvailableExamDate.filter(examDate => examDate.i_ActivityTypeID !== null)
        La_SelectedExamDate.forEach(examDate => {
          this.setActivity(examDate)
        })
      },
      setActivity (Ao_ExamDate) {
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.teacherID}/period/${this.getCurrentExamEvent.i_PlanningPeriodID}/activity`, {
          i_ActivityTypeID: Ao_ExamDate.i_ActivityTypeID,
          t_Start: Ao_ExamDate.t_ExamDate,
          t_End: Ao_ExamDate.t_ExamDate,
        })
          .then(response => {
            this.editMode = false
            if (response.status === 401) {
              // this.signOut()
              // this.$smt.logout()
              return
            }
            this.requestSent = true
            this.updateActivities(Ao_ExamDate, response.i_ActivityID, response.s_ActivityName)
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      deleteActivity (Ai_ActivityID) {
        del(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.teacherID}/period/${this.getCurrentExamEvent.i_PlanningPeriodID}/activity/${Ai_ActivityID}`, {
        })
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            const Li_Index = this.a_Activity.findIndex(activity => activity.i_ActivityID === Ai_ActivityID)
            const Lt_ActivityDate = this.a_Activity[Li_Index].t_Start.substring(0, 10)
            const Li_IndexExamDate = this.a_ExamDate.findIndex(examDate => examDate.t_ExamDate === Lt_ActivityDate)
            this.a_Activity.splice(Li_Index, 1)
            this.a_ExamDate[Li_IndexExamDate].b_Available = true
            this.b_IsLoaded = true
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      updateActivities (Ao_ExamDate, Ai_ActivityID, As_ActivityName) {
        const Li_Index = this.a_ExamDate.findIndex(examDate => examDate.i_EventID === Ao_ExamDate.i_EventID)
        this.a_ExamDate[Li_Index].b_Available = false
        this.a_ExamDate[Li_Index].i_ActivityTypeID = null
        this.a_Activity.push({
          i_ActivityID: Ai_ActivityID,
          s_Name: As_ActivityName,
          t_Start: Ao_ExamDate.t_ExamDate,
          t_End: Ao_ExamDate.t_ExamDate,
          i_ActivityTypeID: Ao_ExamDate.i_ActivityTypeID,
        })
      },
      getNextState (Ai_Type) {
        if(Ai_Type === 1) return 2
        if(Ai_Type === 2) return 3
        if(Ai_Type === 3) return 1
        return 3
      },
      setNextState (Ao_SelectedExamDate) {
        const Li_IndexExamDate = this.a_Activity.findIndex(examDate => examDate.id === Ao_SelectedExamDate.id)
        const Li_NextState = this.getNextState(Ao_SelectedExamDate.type)
        if (Li_IndexExamDate === -1) this.a_Activity[Li_IndexExamDate].type = 3
        else this.a_Activity[Li_IndexExamDate].type = Li_NextState
      },
      getColorChip (Ai_Type) {
        switch (Ai_Type) {
          case 1:
            return 'success'
          case 2:
            return 'orange'
          case 3:
            return 'danger'
          default:
            return 'danger'
        }
      },
      getIconChip (Ai_Type) {
        switch (Ai_Type) {
          case 1:
            return 'mdi-account-check'

          case 2:
            return 'mdi-account-alert'

          case 3:
            return 'mdi-account-cancel'

          default:
            return 'mdi-account-cancel'
        }
      },
      getLabelChip(Ai_State) {
        switch (Ai_State) {
          case 1:
            return 'Verfügbar';

          case 2:
            return 'Wahrscheinlich';

          case 3:
            return 'nicht Verfügbar';

          default:
            return 'nicht Verfügbar';
        }
      },
      getDateFormat (As_Date) {
        let Lo_Date = new Date(As_Date);
        let Ls_DateCHFormat = Lo_Date.getDate() + '.' + (parseInt(Lo_Date.getMonth()) + 1) + '.' + Lo_Date.getFullYear();
        return Ls_DateCHFormat
      },
    },
    created () {
      this.getActivity()
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>